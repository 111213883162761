import axios from "axios";

const fetchSpaceStatus = async (
  { apiProtocol, apiHost },
  { instance, buildingId, spaceId }
) => {
  // build the api host based on the default domain + instance. Exclude 'localhost' instance.
  // OSX seems to resolve *.localhost:3000 but not sure that's universal ¯\_(ツ)_/¯   
  let requestUrl = `${apiHost}/screen/${buildingId}/${spaceId}`;
  requestUrl =
    apiProtocol + (instance !== "localhost" ? instance + "." : "") + requestUrl;

  let axiosPromise = await axios.get(requestUrl).catch(function (error) {
    if (error.response.status === 404) {
      window.location.href = "/error/404";
    }
    return {};
  });

  return axiosPromise;
};

export default fetchSpaceStatus;
