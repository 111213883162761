import Main from "./components/Main";
import Error404 from "./components/Error404";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { DateTime } from "luxon";

const App = () => {
  const refreshPeriod =
    typeof process.env["REACT_APP_REFRESH_PERIOD"] !== "undefined"
      ? process.env["REACT_APP_REFRESH_PERIOD"]
      : 300000;

  /**
   * Timestamp set when the page was loaded. Use a ref so it doesn't reset every page render
   * @type {DateTime}
   */
  const pageLoad = useRef(DateTime.now());

  /**
   * Initial state of the space screen. This can be used for errors, etc
   * @type {{timezone: string, nextAvailable: string, branding: {backgroundColor: string, bookedColor: string, logo: string, closedColor: string, availableColor: string}, deeplink: string, name: string, nextBooking: string, status: string}}
   */
  const initialState = {
    name: "Offline",
    status: "offline",
    nextBooking: "",
    nextAvailable: "",
    timezone: "",
    branding: {
      logo: "",
      backgroundColor: "#FF0000",
      availableColor: "#3D7341",
      bookedColor: "#A23939",
      closedColor: "#383B41",
      offlineColor: "#383B41",
    },
    deeplink: "",
  };

  const [lastRefresh, updateLastRefresh] = useState(pageLoad.current);

  /**
   * Updating the state that is then passed to the Main component will trigger a fetch & rerender in the useEffect
   * Timer is set up with setInterval https://developer.mozilla.org/en-US/docs/Web/API/setInterval
   * If now has rolled over to a new day then do a full page reload.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (!DateTime.now().hasSame(pageLoad.current, "day")) {
        window.location.reload(false);
      }
      updateLastRefresh(DateTime.now());
    }, refreshPeriod);

    return () => clearInterval(interval);
  }, [pageLoad, refreshPeriod]);

  return (
    <div
      className="App h-100 d-flex justify-content-center align-items-end"
      id="app"
    >
      <BrowserRouter>
        <Routes>
          {/*Main screen*/}
          <Route
            path="/:instance/:buildingId/:spaceId/"
            element={
              <Main
                initialState={initialState}
                lastRefresh={lastRefresh}
                apiProtocol={process.env["REACT_APP_API_PROTOCOL"]}
                apiHost={process.env["REACT_APP_API_HOST"]}
              />
            }
          />

          <Route
            path="/error/404"
            element={<Error404 initialState={initialState} />}
          />

          {/* Catch all sends to error page */}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
