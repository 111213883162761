/**
 * Main layout component for the screen
 */

import css from "./Main.module.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetchSpaceStatus from "./fetchSpaceStatus";
import QRCode from "react-qr-code";
import { DateTime } from "luxon";
import { Col, Container, Row } from "react-bootstrap";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Main = (props) => {
  let urlParams = useParams();
  const [spaceState, updateSpace] = useState(props.initialState);
  let dtNextAvailable = DateTime.fromISO(spaceState.nextAvailable).setZone(
    spaceState.timezone
  );

  // any time the component renders we need to set the background to match the state
  document.body.style.backgroundColor =
    spaceState.branding[spaceState.status + "Color"];

  // create the luxon objects we will use later

  /*
   * Watch the promise and triggers an update of the space when it is fulfilled
   */
  useEffect(() => {
    const fetchData = async (props, urlParams) => {
      const response = await fetchSpaceStatus(props, urlParams);
      if ("data" in response) {
        updateSpace(response.data);
      } else {
        // Currently we just want to show the Offline state if there is an error
        updateSpace(props.initialState);
      }
    };

    fetchData(props, urlParams);
  }, [props, urlParams]);

  return (
    <Container fluid className="pt-5" data-testid="main-container">
      <Row>
        <Col className="ps-0">
          {spaceState.status !== "offline" && (
            <div
              className={`ps-4 pe-3 py-3 float-start rounded-end bg-white fw-bold text-left fs-1 w-auto`}
            >
              <div data-testid="space-name" className={css.spaceName}>
                {spaceState.name}
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col />
        {/* Scan here to book */}
        <Col
          className="text-white fw-bold fs-1 text-center ps-3 pe-4 mt-2"
          data-testid="scan-to-book"
        >
          {spaceState.deeplink !== "" && "Scan here to book"}
        </Col>
      </Row>
      <Row className="text-start ms-4 pb-5">
        <Col>
          <Row>
            <Col
              className={`p-2 text-white ${css.textLarge}`}
              data-testid="current-time"
            >
              {/* format tokens can be found here https://moment.github.io/luxon/#/formatting?id=table-of-tokens */}
              {spaceState.status !== "offline" &&
                DateTime.now()
                  .setZone(spaceState.timezone)
                  .toFormat("cccc d LLLL yyyy, h:mm a")}
            </Col>
          </Row>
          <Row>
            <Col className="p-2 text-white text-capitalize">
              {/* Capitalise the first character of the status via bootstrap*/}
              <div
                className={`fw-bold ${css.spaceStatus}`}
                data-testid="space-status"
              >
                {spaceState.status}
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              className={`p-2 text-white ${css.textLarger}`}
              data-testid="next-available-label"
            >
              {spaceState.nextAvailable !== "" && "Next Booking:"}
            </Col>
          </Row>
          <Row>
            {/* Next available time */}
            <Col
              className={`p-2 text-white fw-bold ${css.textLarge}`}
              data-testid="next-available-time"
            >
              {spaceState.nextAvailable !== "" &&
                dtNextAvailable.toFormat("cccc d LLLL yyyy, h:mm a")}
            </Col>
          </Row>
        </Col>
        <Col
          data-testid="deep-link"
          className="ps-0 pe-4 text-center d-flex align-items-center justify-content-center"
        >
          {/* Show QR code if there is a link */}
          {spaceState.deeplink !== "" && (
            <div className={`bg-white m-0 p-3 rounded ${css.qrCode}`}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={spaceState.deeplink}
                viewBox={`0 0 256 256`}
                data-testid="qrcode-svg"
              />
            </div>
          )}
        </Col>
      </Row>
      <Row className={`bg-white mt-2 ${css.footerRow}`}>
        {/* Footer logo */}
        <Col
          className="pe-5 text-end d-flex align-items-center justify-content-end"
          data-testid="branding-logo"
        >
          {/* Only show the logo if it has a value */}
          {spaceState.branding.logo !== "" && (
            <img
              className={css.footerLogo}
              src={spaceState.branding.logo}
              alt=""
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
