/**
 * 404 page
 */

import css from "./Error404.module.css";
import { Container } from "react-bootstrap";

/**
 * Default 404 page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Error404 = ({ initialState }) => {
  // any time the component renders we need to set the background to match the state
  document.body.style.backgroundColor = initialState.branding.offlineColor;

  return (
    <Container
      fluid
      className={`h-100 text-white text-capitalize d-flex align-items-center justify-content-center ${css.errorStatus}`}
      data-testid="main-container"
    >
      {initialState.status}
    </Container>
  );
};

export default Error404;
